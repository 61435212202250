<template>
  <div style="padding-bottom:44px">
    <van-field label="序列号" v-model="pzd001_Step2.serialNumber">
      <template #right-icon>
        <van-tag type="warning">{{jieMa}}</van-tag>
      </template>
    </van-field>
    <van-cell title="版本号确认">
      <template #right-icon>
        <van-checkbox v-model="pzd001_Step2.banBenHaoQueRen" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="3.3V检测">
      <template #right-icon>
        <van-checkbox v-model="pzd001_Step2.sanDianSanCeLiang" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="按键测试">
      <template #right-icon>
        <van-checkbox v-model="pzd001_Step2.anJianCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="计数测试">
      <template #right-icon>
        <van-checkbox v-model="pzd001_Step2.jiShuCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="位移标定检定">
      <template #right-icon>
        <van-checkbox v-model="pzd001_Step2.weiYiBiaoDingJianDing" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="蜂鸣器">
      <template #right-icon>
        <van-checkbox v-model="pzd001_Step2.fengMingQi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="时间检测">
      <template #right-icon>
        <van-checkbox v-model="pzd001_Step2.shiJianJianCe" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="USB检测">
      <template #right-icon>
        <van-checkbox v-model="pzd001_Step2.usbJianCe" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="文件检测">
      <template #right-icon>
        <van-checkbox v-model="pzd001_Step2.wenJianJianCe" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="系统升级检测">
      <template #right-icon>
        <van-checkbox v-model="pzd001_Step2.xiTongShengJiJianCe" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="蓝牙检测">
      <template #right-icon>
        <van-checkbox v-model="pzd001_Step2.lanYaJianCe" shape="square"></van-checkbox>
      </template>
    </van-cell>
    
    <van-field label="备注" v-model="pzd001_Step2.remark"> </van-field>
    <el-button type="primary" class="footer1" icon="el-icon-view" circle @click="handlePicClick"></el-button>
    <div class="footer">
      <van-button type="info" v-show="$parent.hasPower_Step2" round block :disabled="$parent.pzd001_SignNumber.status!=0" @click="handleSave">保存</van-button>
      <van-button type="danger" v-if="$parent.pzd001_SignNumber.status==2" round block @click="$parent.cancel_void_SignNumber()">取消作废</van-button>
      <van-button type="danger" v-else-if="$parent.pzd001_SignNumber.status!=1" round block @click="$parent.void_SignNumber()">作废</van-button>

    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  props: ["signNumber"],
  data() {
    return {
      pzd001_Step2: {},
      jieMa: "",
    };
  },
  methods: {
    handlePicClick() {
      ImagePreview(["./images/PZD001_Produce.png"]);
    },
    getPZD001_Step2() {
      let that = this;
      that.axios
        .post("/PZD001/GetPZD001_Step2", { onlyText: that.signNumber })
        .then(function (response) {
          that.pzd001_Step2 = response.data.data;
        });
    },
    handleSave() {
      let that = this;
      that.axios
        .all([that.save_PZD001_Step2(), that.registerSerialNumber()])
        .then(
          that.axios.spread(function (response1, response2) {
            that.pzd001_Step2 = response1.data.data;
            if (response1.data.code == 201) {
              that.$notify({ type: "warning", message: response1.data.msg });
            } else if (response2.data.code == 201) {
              that.$notify({ type: "warning", message: response2.data.msg });
            } else {
              that.$notify({ type: "success", message: response1.data.msg });
            }
            if (response2.data.code == 101) {
              that.jieMa = response2.data.msg;
            }
          })
        );
    },
    save_PZD001_Step2() {
      let that = this;
      return that.axios.post("PZD001/UpdatePZD001_Step2", that.pzd001_Step2);
    },
    registerSerialNumber() {
      let that = this;
      return that.axios.post("Base_Device/Register", {
        productName: "八轮平整度",
        productId: 1078,
        serialNumber: that.pzd001_Step2.serialNumber,
        deviceType: "PZD001",
        companyName: "",
        ifPrint: false,
      });
    },
  },
  mounted() {
    let that = this;
    that.getPZD001_Step2();
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
.footer1 {
  position: fixed;
  left: 10px;
  bottom: 50px;
}
</style>